import { Link, useLocation } from "react-router-dom";
import React, { useState } from "react";
export default function Navbar() {
  const location = useLocation();
  const currentPath = location.pathname.split("/")[1];
  const [activeLink, setActiveLink] = useState(
    currentPath !== "" ? currentPath : "home"
  );
  const [isMenuOpen, setIsMenuOpen] = useState(true);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLinkClick = (link) => {
    setIsMenuOpen(true);
    setActiveLink(link);
  };
  return (
    <>
      <header
        className="text-white m-3 md:rounded-[35px] rounded-lg"
        style={{
          background:
            "linear-gradient(135deg, #16425b 0%, #1d4f6f 50%, #2a6c8c 100%)",
        }}
      >
        <nav className="border-gray-200">
          <div className="max-w-screen-2xl flex flex-wrap items-center justify-between mx-auto md:mb-0 p-4">
            <Link
              to="/"
              className="flex items-center space-x-3 rtl:space-x-reverse"
            >
              {/* <span className="text-2xl font-semibold whitespace-nowrap">Web-game</span> */}
              <img src="/navbar.png" className="h-15" alt="serenevista.site" />
            </Link>

            <button
              data-collapse-toggle="navbar-search"
              type="button"
              className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-white rounded-lg md:hidden hover:bg-[#17435D] focus:outline-none focus:ring-2 focus:ring-gray-200"
              aria-controls="navbar-search"
              aria-expanded={isMenuOpen ? "true" : "false"}
              onClick={handleMenuToggle}
            >
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>

            <div
              id="navbar-search"
              className={`absolute top-[5.5rem] inset-x-0 z-40 flex justify-center mx-3 text-white rounded-lg md:static md:!bg-none md:flex md:items-center md:justify-between md:w-auto md:bg-transparent ${
                isMenuOpen ? "block" : "hidden"
              }`}
              style={{
                background:
                  "linear-gradient(135deg, #16425b 0%, #1d4f6f 50%, #2a6c8c 100%)",
              }}
            >
              <ul className="md:flex flex-col p-4 font-medium rounded-lg md:flex-row md:space-x-8 md:p-0 md:mt-0 md:bg-transparent md:!bg-none inline-flex text-center">
                <li>
                  <Link
                    to="/"
                    className={`block py-2 px-3 hover:border-b hover:border-[#17435D] md:hover:bg-transparent ${
                      activeLink === "home" ? "font-bold" : "text-white"
                    }`}
                    onClick={() => {
                      handleLinkClick("home");
                      setIsMenuOpen(false);
                    }}
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    to="/about"
                    className={`block py-2 px-3 hover:border-b hover:border-[#17435D] md:hover:bg-transparent ${
                      activeLink === "about" ? "font-bold" : "text-white"
                    }`}
                    onClick={() => {
                      handleLinkClick("about");
                      setIsMenuOpen(false);
                    }}
                  >
                    About
                  </Link>
                </li>
                <li>
                  <Link
                    to="/privacy-policy"
                    className={`block py-2 px-3 hover:border-b hover:border-[#17435D] md:hover:bg-transparent ${
                      activeLink === "privacy-policy"
                        ? "font-bold"
                        : "text-white"
                    }`}
                    onClick={() => {
                      handleLinkClick("privacy-policy");
                      setIsMenuOpen(false);
                    }}
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link
                    to="/term-condition"
                    className={`block py-2 px-3 hover:border-b hover:border-[#17435D] md:hover:bg-transparent ${
                      activeLink === "term-condition"
                        ? "font-bold"
                        : "text-white"
                    }`}
                    onClick={() => {
                      handleLinkClick("term-condition");
                      setIsMenuOpen(false);
                    }}
                  >
                    Term Condition
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contact-us"
                    className={`block py-2 px-3 hover:border-b hover:border-[#17435D] md:hover:bg-transparent ${
                      activeLink === "contact-us" ? "font-bold" : "text-white"
                    }`}
                    onClick={() => {
                      handleLinkClick("contact-us");
                      setIsMenuOpen(false);
                    }}
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}

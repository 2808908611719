import { Route, Routes } from "react-router-dom";
import Navbar from "./Component/Navbar";
import Footer from "./Component/Footer";
import Home from "./Component/Home";
import About from "./Component/About";
import PrivacyPolicy from "./Component/PrivacyPolicy";
import Single from "./Component/Single";
import ContactUs from "./Component/ContactUs";
import TermCondition from "./Component/TermCondition";

function App() {
  return (
    <div className="flex flex-col min-h-screen main-div">
      <Navbar />
      <div className="flex-grow container mx-auto p-3">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/about" element={<About />} />
          <Route path="/single" element={<Single />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/term-condition" element={<TermCondition />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;

import React, { useState } from "react";
import Loader from "./Loader";

export default function PrivacyPolicy() {
  const [isLoad, setIsLoad] = useState(true);
  setTimeout(() => {
    setIsLoad(false);
  }, 500);
  return isLoad ? (
    <Loader />
  ) : (
    <section className="p-6 text-gray-800 rounded-lg shadow-md m-4">
      <h1 className="text-3xl font-bold text-center mb-6">Privacy Policy</h1>

      <h2 className="text-2xl font-semibold mb-4">Introduction</h2>
      <p className="mb-4">
        At Sphere Institution, we are committed to protecting your privacy. This
        Privacy Policy explains how we collect, use, and safeguard your personal
        information.
      </p>

      <h2 className="text-2xl font-semibold mb-4">Information We Collect</h2>
      <ul className="list-disc list-inside mb-4">
        <li className="mb-2">
          <strong>Personal Information:</strong> We collect personal information
          such as your name, email address, and contact details when you
          register on our website or use our services.
        </li>
        <li className="mb-2">
          <strong>Educational Data:</strong> We collect information related to
          your educational activities, such as your interactions with our
          resources and participation in programs.
        </li>
        <li className="mb-2">
          <strong>Usage Data:</strong> We collect information about how you use
          our website, including your IP address, browser type, and access
          times.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mb-4">
        How We Use Your Information
      </h2>
      <ul className="list-disc list-inside mb-4">
        <li className="mb-2">
          <strong>To Provide Services:</strong> We use your information to
          deliver and improve our educational resources and support services.
        </li>
        <li className="mb-2">
          <strong>To Improve Our Services:</strong> We analyze usage data to
          enhance our website and service offerings.
        </li>
        <li className="mb-2">
          <strong>To Communicate:</strong> We use your contact information to
          send important updates and respond to your inquiries.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mb-4">Information Sharing</h2>
      <p className="mb-4">
        We do not sell, trade, or otherwise transfer your personal information
        to outside parties, except as required by law or to trusted third
        parties who assist us in operating our website and services.
      </p>

      <h2 className="text-2xl font-semibold mb-4">Data Security</h2>
      <p className="mb-4">
        We implement a variety of security measures to protect your personal
        information. However, no method of transmission over the internet or
        electronic storage is 100% secure.
      </p>

      <h2 className="text-2xl font-semibold mb-4">Changes to This Policy</h2>
      <p className="mb-4">
        We may update this Privacy Policy from time to time. Changes will be
        posted on this page, and the effective date will be updated.
      </p>

      <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, please contact us
        at{" "}
        <a
          href="mailto:privacy@sphereinstitution.online"
          className="text-blue-500 hover:underline"
        >
          privacy@sphereinstitution.online
        </a>
        .
      </p>
    </section>
  );
}

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Games from "../Games.json";
import Loader from "./Loader";

const shuffleArray = (array) => {
  return array.sort(() => Math.random() - 0.5);
};

export default function Home() {
  const [isLoad, setIsLoad] = useState(true);
  const [randomGames, setRandomGames] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  useEffect(() => {
    const shuffledGames = shuffleArray([...Games]).slice(0, 20);
    setRandomGames(shuffledGames);
  }, []);

  setTimeout(() => {
    setIsLoad(false);
  }, 500);

  return isLoad ? (
    <Loader />
  ) : (
    <section className="flex items-center justify-center p-8">
      <div className="grid lg:grid-cols-3  md:grid-cols-3 grid-cols-1 gap-9 w-full">
        {randomGames.map((data, index) => (
          <div
            className={`relative rounded-lg overflow-hidden shadow-lg shadow-gray-600 transform transition duration-300 bg-white dark:bg-gray-700 ${
              hoveredIndex === index ? "hover:scale-95 hover:shadow-xl" : ""
            }`}
            key={index}
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
          >
            <img className="w-full" src={data.logo} alt={data.title} />

            <div
              className={`absolute inset-0 flex items-end justify-center bg-black bg-opacity-50 transition-opacity duration-300 ${
                hoveredIndex === index ? "opacity-100" : "opacity-0"
              }`}
            >
              <Link
                to={`/single?slug=${data.slug}`}
                className="bg-white text-xl w-full"
              >
                <div className="flex justify-between py-2 px-4">
                  <div className="flex items-center justify-center">
                    <span className="text-xl font-bold text-[#16425b] dark:text-gray-100">
                      {data.title}
                    </span>
                  </div>
                  <div className="bg-gradient-to-r from-[#16425b] via-[#1d4f6f] to-[#2a6c8c] text-[#fff] rounded-full p-2 ">
                    <svg
                      className="w-8 h-8"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M14.752 11.168l-6.207-3.695A1 1 0 007 8.305v7.39a1 1 0 001.545.832l6.207-3.695a1 1 0 000-1.664z"
                      />
                    </svg>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

import React, { useState } from "react";
import Loader from "./Loader";

export default function About() {
  const [isLoad, setIsLoad] = useState(true);
  setTimeout(() => {
    setIsLoad(false);
  }, 500);
  return isLoad ? (
    <Loader />
  ) : (
    <section className="p-6 text-gray-800 rounded-lg shadow-md m-4">
      <h1 className="text-3xl font-bold text-center mb-6">About Us</h1>

      <p className="text-lg mb-4">
        <strong>Welcome to Sphere Institution!</strong>
        <br />
        At Sphere Institution, we are committed to fostering educational
        excellence and innovation. Our mission is to provide high-quality
        educational resources and support to learners and educators, helping
        them achieve their full potential.
      </p>

      <h2 className="text-2xl font-semibold mb-4">Our Story</h2>
      <p className="mb-4">
        Founded in 2024, Sphere Institution was established with the vision of
        advancing education through cutting-edge resources and support. We
        strive to enhance learning experiences and outcomes by offering a
        diverse range of educational tools and services.
      </p>

      <h2 className="text-2xl font-semibold mb-4">Our Team</h2>
      <p className="mb-4">
        Our team comprises experienced educators, industry professionals, and
        support staff who are dedicated to advancing education and helping our
        users succeed. We work together to ensure that our resources and
        services meet the highest standards of quality and effectiveness.
      </p>

      <h2 className="text-2xl font-semibold mb-4">Our Services</h2>
      <ul className="list-disc list-inside mb-4">
        <li className="mb-2">
          <strong>Educational Resources:</strong> Access a comprehensive library
          of educational materials and tools designed to support learning and
          teaching.
        </li>
        <li className="mb-2">
          <strong>Training Programs:</strong> Participate in training programs
          and workshops tailored to enhance skills and knowledge.
        </li>
        <li className="mb-2">
          <strong>Support Services:</strong> Receive assistance and support to
          help you make the most of our educational resources and services.
        </li>
      </ul>

      <p>
        Thank you for choosing Sphere Institution. We are excited to support
        your educational journey and contribute to your success.
      </p>
    </section>
  );
}
